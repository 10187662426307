
import { Component, Prop, Vue, Watch, Model, Ref } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import { Customer } from '@/types/customer'

interface DebouncedFn {
  (): Promise<void>
}

@Component({
  components: {},
})
export default class CustomerAutocomplete extends Vue {
  @Model('change', { default: null }) readonly value!: string | null
  @Prop({ default: 200 }) readonly debounce!: number
  @Prop({ default: '顧客検索' }) readonly label!: string

  @Ref() readonly autocomplete!: any
  _fetchDataDebounce: DebouncedFn | null = null

  isLoading = false
  items: Customer[] = []
  search = ''
  customer: null | Customer = null
  get itemsIncludeValue() {
    let uuid = this.value
    return this.items.find(x => x.uuid === uuid)
  }

  get itemsWithTarget() {
    if (!this.customer) return this.items
    let uuid = this.customer.uuid
    let items = this.items.filter(x => x.uuid !== uuid)
    return [this.customer].concat(items)
  }

  get transformedSearch() {
    return this.transform(this.search)
  }
  set transformedSearch(value: any) {
    this.search = this.transform(String(value || ''))
  }

  @Watch('search', { immediate: true })
  onSearch(search: string) {
    // console.log('search', search, uuid)
    if (!this.itemsIncludeValue) {
      this._fetchDataDebounce?.call(this)
    }
  }

  @Watch('value', { immediate: true })
  async onValueChanged(value: string | null) {
    // NOTE: valueに初期値が与えられている場合の処理
    // console.log('onValue changed', value)
    if (!value) {
      this.search = ''
      // NOTE: clearされたときに一番上に前回選択アイテムを出すためにここでは
      // this.customerをクリアしない
      this.$emit('customer', null)
    } else {
      this.search = ''
      if (!this.itemsIncludeValue) {
        this.customer = await this.$api.customers(value).get()
      } else {
        this.customer = this.items.find(x => x.uuid === value) || null
      }
    }
  }

  @Watch('debounce')
  onChangeDebounce(value: number) {
    this._fetchDataDebounce = debounce(this.fetchData, value) as DebouncedFn
  }

  @Watch('customer')
  onCustomerChanged() {
    this.$emit('customer', this.customer)
  }

  created() {
    this._fetchDataDebounce = debounce(
      this.fetchData,
      this.debounce
    ) as DebouncedFn
  }

  async fetchData() {
    if (this.isLoading) return
    let search = this.search
    try {
      this.isLoading = true
      let { results } = await this.$api.customers().list({ search })
      this.items = results
    } catch (err) {
      console.error(err)
      this.$toast.error('顧客データの取得に失敗しました。')
    }
    this.isLoading = false
    if (this.search !== search) {
      // NOTE: 漢字変換でsearchが違うことがあるのでもう一度検索
      this.fetchData()
    }
  }

  getText(obj: Customer) {
    const shopName = obj.aggregation?.maxVisitShopName || obj.shopName
    if (obj.familyName) {
      return `${obj.account} ${obj.familyName} ${obj.givenName} (${obj.familyNameKana} ${obj.givenNameKana}) ${obj.phoneNumber} ${shopName}`
    }
    return `${obj.account} ${obj.familyNameKana} ${obj.givenNameKana} ${obj.phoneNumber} ${shopName}`
  }

  onChange(uuid: string) {
    this.$emit('change', uuid || null)
  }

  customFilter(item: Customer[], queryText: string, itemText: string) {
    // NOTE: 電話番号のハイフンを消してマッチ
    let searchTexts = queryText
      .replaceAll('-', '')
      .split(' ')
      .filter(x => x)
    itemText = itemText.replaceAll('-', '')
    // console.log(searchTexts, itemText)
    return searchTexts.some(s => itemText.indexOf(s) > -1)
  }

  transform(value: string) {
    // NOTE: 全角スペースを半角に変換
    return value.replaceAll('　', ' ')
  }

  focus() {
    const input = this.$el?.querySelector('input')
    console.log('?', input)
    input?.focus()
    this.autocomplete.isMenuActive = true
    return input
  }
}
